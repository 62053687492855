<template>
  <div id="Console">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Console',
  };
</script>

<style lang="scss" scoped>
  #Console {
    height: 100%;
  }
</style>
